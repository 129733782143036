import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import {
  formatToThreeSignificant,
  getHouseEmojiFromHouseName,
} from "./../../utils";
import _classes from "./ViewCard.module.scss";

function ViewCard(props) {
  const { name, score, weeklyScore, lastWeekScore, loading } = props;

  const _renderContent = () => {
    if (loading) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <p className={_classes["view-card__emoji"]}>
            {getHouseEmojiFromHouseName(name)}
          </p>
          <p className={_classes["view-card__title"]}>{name}</p>
          <p className={_classes["view-card__time-1"]}>This week</p>
          <p className={_classes["view-card__time-2"]}>
            (resets sunday midnight)
          </p>
          <p className={_classes["view-card__points"]}>
            {formatToThreeSignificant(weeklyScore)}
          </p>
          <div className={_classes["view-card__week-all"]}>
            <div>
              <p className={_classes["view-card__all"]}>LAST WEEK</p>
              <p className={_classes["view-card__all_points"]}>
                {formatToThreeSignificant(lastWeekScore)}
              </p>
            </div>

            <div>
              <p className={_classes["view-card__all"]}>ALL TIME</p>
              <p className={_classes["view-card__all_points"]}>
                {formatToThreeSignificant(score)}
              </p>
            </div>
          </div>
        </>
      );
    }
  };

  return <div className={_classes["view-card"]}>{_renderContent()}</div>;
}

export default ViewCard;
