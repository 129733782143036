import { ThemeProvider, createTheme } from "@mui/material/styles";
import MainPage from "./pages/MainPage/MainPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a6894d",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MainPage />
    </ThemeProvider>
  );
}

export default App;
