import clsx from "clsx";
import React from "react";
import _classes from "./Container.module.scss";

function MyContainer({ children, style, className }) {
  return (
    <div
      className={clsx({
        [_classes["my-container"]]: true,
        [className]: className,
      })}
      style={style}
    >
      {children}
    </div>
  );
}

export default MyContainer;

MyContainer.defaultProps = {
  style: {},
  children: null,
};
