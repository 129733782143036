import React from "react";
import MainLogo from "../../../common/assets/icons/logo.png";
import _classes from "./LogoTitle.module.scss";

export default function LogoTitle() {
  return (
    <div className={_classes["logo-section"]}>
      <div className={_classes["logo-section__logo"]}>
        <img src={MainLogo} alt="logo" height="100" width="100" />
      </div>
      <p className={_classes["logo-section__title"]}>ECONOMY LEADERBOARD</p>
      <div className={_classes["logo-section__description"]}>
        <p>
          The Tulip Head Society economy is powered by Tulip Banker. Members of
          the Society can choose to work, claim and play games, like roulette,
          to gain the society's currency Guilders. These Guilders are each week
          totalled up by Tulip Banker with each house being rewarded with SOL,
          (the greater the amount of Guilders the great the amount of SOL).
          Which they can stack or choose to invest in NFTs.
        </p>
        <p>
          The house with the largest balance before Mint will receive a special
          prize.
        </p>
      </div>
    </div>
  );
}
