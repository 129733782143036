import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import ViewCard from "../ViewCard/ViewCard";

export default function LeadersCards() {
  const [houses, setHouses] = useState([]);
  const [loading, setLoading] = useState(true);

  const getHouses = async () => {
    let housesData = [];

    try {
      const response = await fetch("https://ths.stage4000.com/houses/");
      const data = await response.json();

      for (let key in data) {
        housesData.push(data[key]);
      }

      setHouses(housesData[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHouses();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: "0 2rem", marginBottom: "2rem" }}
    >
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <ViewCard
          name="couleren"
          score={houses?.couleren}
          weeklyScore={houses?.coulerenweekly}
          loading={loading}
          lastWeekScore={houses?.coulerenlastweek}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <ViewCard
          name="ROSEN"
          score={houses?.rosen}
          weeklyScore={houses?.rosenweekly}
          loading={loading}
          lastWeekScore={houses?.rosenlastweek}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <ViewCard
          name="bizarren"
          score={houses?.bizarren}
          weeklyScore={houses?.bizarrenweekly}
          loading={loading}
          lastWeekScore={houses?.bizarrenlastweek}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <ViewCard
          name="violetten"
          score={houses?.violetten}
          weeklyScore={houses?.violettenweekly}
          loading={loading}
          lastWeekScore={houses?.violettenlastweek}
        />
      </Grid>
    </Grid>
  );
}
