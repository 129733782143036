import MyContainer from "../../common/components/Container/Container";
import _classes from "./MainPage.module.scss";
import LogoTitle from "../../common/components/LogoTitle/LogoTitle";
import LeadersCards from "../../common/components/LeadersCards/LeadersCards";
import UsersTable from "../../common/components/UsersTable/UsersTable";

function MainPage() {
  return (
    <div className={_classes["main-page"]}>
      <MyContainer className={_classes["main-page__container"]}>
        <LogoTitle />
        <LeadersCards />
        <UsersTable />
      </MyContainer>
    </div>
  );
}

export default MainPage;
