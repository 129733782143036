import React, { useEffect, useState } from "react";
import { useScrollTrigger, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import { useMedia } from "react-use";
import CircularProgress from "@mui/material/CircularProgress";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import CurrentRank from "../CurrentRank/CurrentRank";
import _classes from "./UsersTable.module.scss";
import {
  getHouseEmojiFromHouseName,
  getComparator,
  stableSort,
  getTruncatedText,
  formatToThreeSignificant,
} from "../../utils";

const tableHeaderCellStyle = {
  fontFamily: "Jost",
  backgroundColor: "#1c1c1c",
  color: "#545454",
  borderBottomColor: "#111111",
  textTransform: "uppercase",
  fontSize: "0.8rem",
};

const tableBodyCellStyle = (isMobile, index) => ({
  fontFamily: "EB Garamond",
  backgroundColor: "#262626",
  color: "#fff",
  borderBottomColor: "#111111",
  fontSize: "1rem",
  paddingRight: isMobile ? "0px" : "",
});

const getColumns = (isMobile) => [
  {
    id: "rank",
    label: "Rank",
    sortable: true,
    visibleOnMobile: true,
    visible: true,
  },
  {
    id: "name",
    label: "Member",
    minWidth: isMobile ? 0 : "160px",
    sortable: true,
    visibleOnMobile: true,
    visible: true,
  },
  {
    id: "house",
    label: "House",
    align: "center",
    minWidth: isMobile ? 0 : "160px",
    sortable: true,
    visibleOnMobile: true,
    visible: true,
  },
  {
    id: "weeklyactivity",
    label: "This Week",
    align: "center",
    minWidth: isMobile ? 0 : "50px",
    sortable: true,
    visibleOnMobile: true,
    visible: true,
  },
  {
    id: "lastweekactivity",
    label: "Last Week",
    align: "center",
    minWidth: isMobile ? 0 : "50px",
    sortable: true,
    visibleOnMobile: false,
    visible: true,
  },
  {
    id: "totalactivity",
    label: "All Time",
    align: "center",
    minWidth: isMobile ? 0 : "50px",
    sortable: true,
    visibleOnMobile: false,
    visible: true,
  },
  {
    id: "balance",
    label: "Balance",
    align: "center",
    minWidth: isMobile ? 0 : "50px",
    sortable: true,
    visibleOnMobile: false,
    visible: true,
  },
];

const useStyles = makeStyles({
  root: {
    "&$active": {
      color: "#fff",
    },
    "&:hover": {
      color: "#fff",
    },
  },
  icon: {
    color: "#fff !important",
    marginRight: 0,
  },
  active: {}, // pseudo
});

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const generateRanks = (list, order, orderBy) => {
  const sortedArray = stableSort(list, getComparator(order, orderBy)).map(
    (user, index) => ({
      ...user,
      rank: index + 1,
      balance: user.balance || 0,
      totalactivity: user.totalactivity || 0,
      lastweekactivity: user.lastweekactivity || 0,
      weeklyactivity: user.weeklyactivity || 0,
    })
  );
  return sortedArray;
};

export default function UsersTable() {
  const [users, setUsers] = useState([]);
  const isTablet = useMedia("(max-width: 768px)");
  const isMobile = useMedia("(max-width: 576px)");
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = useState("rank");

  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    let updatedUsers = [...users];
    if (
      property === "totalactivity" ||
      property === "weeklyactivity" ||
      property === "lastweekactivity"
    ) {
      updatedUsers = generateRanks(updatedUsers, "desc", property);
      if (newOrder === "asc") {
        updatedUsers = updatedUsers.reverse();
      }
    } else {
      updatedUsers = generateRanks(updatedUsers, "desc", "balance");
    }
    setUsers(updatedUsers);
    setOrder(newOrder);
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const getUsers = async () => {
    let userData = [];

    try {
      const response = await fetch("https://ths.stage4000.com/users/");
      const data = await response.json();

      for (let key in data) {
        if (data[key]?.house) {
          userData.push(data[key]);
        }
      }

      setUsers(generateRanks(userData, "desc", "balance"));
      setLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getHouse = (houseName = "") => {
    let house = getHouseEmojiFromHouseName(houseName);

    if (!house) return "";
    else if (isTablet) return house;
    else {
      house += ` | ${houseName.toUpperCase()}`;
      return house;
    }
  };

  let sortedList = stableSort(
    users,
    getComparator(order, orderBy === "balance" ? "rank" : orderBy)
  );

  if (orderBy === "name") {
    sortedList = sortedList.sort(function (a, b) {
      var textA = a.name.toUpperCase().trim();
      var textB = b.name.toUpperCase().trim();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    if (order === "asc") {
      sortedList = sortedList.reverse();
    }
  }

  return (
    <Grid
      container
      sx={{ padding: isMobile ? 0 : "0 2rem", marginBottom: "2rem" }}
    >
      <Table stickyHeader>
        <TableHead>
          <ElevationScroll>
            <TableRow>
              {getColumns(isMobile)
                .filter(({ visibleOnMobile, visible }) => {
                  if (isTablet) return visibleOnMobile && visible;
                  return visible;
                })
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      paddingLeft: isMobile ? "8px" : "",
                      paddingRight: isMobile ? "8px" : "",
                    }}
                    sx={tableHeaderCellStyle}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    {!column.sortable ? (
                      column.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                        classes={{
                          root: classes.root,
                          active: classes.active,
                          icon: classes.icon,
                          iconDirectionDesc: classes.iconDirectionDesc,
                          iconDirectionAsc: classes.iconDirectionAsc,
                        }}
                        hideSortIcon={orderBy !== column.id}
                      >
                        {column.label}
                        {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
            </TableRow>
          </ElevationScroll>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={12} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            sortedList.map((user, index) => (
              <TableRow key={`${user.name}_${index}`}>
                <TableCell sx={tableBodyCellStyle(isMobile, index)}>
                  <div className={_classes["rank-inner"]}>
                    <CurrentRank rank={user?.rank} />
                    <div className={_classes["rank-inner__avatar"]}>
                      <img loading="lazy" src={user?.avatar} alt="" />
                    </div>
                  </div>
                </TableCell>
                <TableCell sx={tableBodyCellStyle(isMobile)}>
                  {getTruncatedText(user?.name, isMobile) || ""}
                </TableCell>
                <TableCell align="center" sx={tableBodyCellStyle(isMobile)}>
                  {getHouse(user?.house || "")}
                </TableCell>
                <TableCell align="center" sx={tableBodyCellStyle(isMobile)}>
                  {user?.weeklyactivity
                    ? formatToThreeSignificant(user.weeklyactivity)
                    : 0}
                </TableCell>
                {!isTablet && (
                  <TableCell align="center" sx={tableBodyCellStyle(isMobile)}>
                    {user?.lastweekactivity
                      ? formatToThreeSignificant(user.lastweekactivity)
                      : 0}
                  </TableCell>
                )}
                {!isTablet && (
                  <TableCell align="center" sx={tableBodyCellStyle(isMobile)}>
                    {user?.totalactivity
                      ? formatToThreeSignificant(user.totalactivity)
                      : 0}
                  </TableCell>
                )}
                {!isTablet && (
                  <TableCell align="center" sx={tableBodyCellStyle(isMobile)}>
                    {user?.balance ? formatToThreeSignificant(user.balance) : 0}
                  </TableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Grid>
  );
}
