import _classes from "./CurrentRank.module.scss";

function CurrentRank({ rank }) {
  function getRankClassName(rankLocal) {
    let rankClassName = "";
    if (rankLocal === 1) {
      rankClassName = "rank-1";
    } else if (rankLocal === 2) {
      rankClassName = "rank-2";
    } else if (rankLocal === 3) {
      rankClassName = "rank-3";
    }
    return rankClassName;
  }
  return (
    <div
      className={`${_classes["current-rank"]} 
        ${_classes[getRankClassName(rank)] ?? ""}`}
    >
      {rank}
    </div>
  );
}

export default CurrentRank;
